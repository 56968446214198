"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createPatientApi = void 0;
function createPatientApi(roseApi) {
    return {
        getPatientDetails: (params) => roseApi.metrics.get(`/api/patient/details/${params.patid}`, { query: params }),
        getPatienten: (patids) => roseApi.metrics.post('/api/patient/patienten', { patids }),
    };
}
exports.createPatientApi = createPatientApi;
