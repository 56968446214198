"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createCollectorTriggerApi = void 0;
const types_1 = require("../../../../types");
function createCollectorTriggerApi(roseApi) {
    return {
        executeAutoBehandlerZuordnung: (fake, reset) => roseApi.collector.get(`/api/trigger/behandlerautoconfig/${fake}/${reset}`),
        abrechnung: (notify = false) => {
            const query = {
                [types_1.QUERYSTRINGS.NONOTIFY]: notify ? undefined : 1,
            };
            return roseApi.collector.get(`/api/trigger/abrechnungsautomat`, { query });
        },
        heuteUpdate: (notify = false) => {
            const query = {
                [types_1.QUERYSTRINGS.NONOTIFY]: notify ? undefined : 1,
            };
            return roseApi.collector.get(`/api/trigger/heute`, { query });
        },
        terminArtUpdate: () => roseApi.collector.get(`/api/trigger/terminartupdate`),
    };
}
exports.createCollectorTriggerApi = createCollectorTriggerApi;
