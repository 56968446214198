"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fakeTitle = exports.navigateWithViewTransition = exports.adminLevelString = exports.unreactiveVueObject = exports.logVueReactiveObject = exports.arrayMove = exports.getStunden = exports.wochentage = exports.recurring = exports.forever = exports.wait = exports.hkpMetaComparer = void 0;
const base_1 = require("../../base");
const types_1 = require("../../types");
const lodash_1 = require("lodash");
function hkpMetaComparer(a, b) {
    let i = 0;
    if (a && b) {
        if (a.frist && b.frist) {
            i = a.frist.valueOf() - b.frist.valueOf();
        }
        else {
            if (a.frist) {
                i = 1;
            }
            else {
                if (b.frist) {
                    i = -1;
                }
                else {
                    // both frist === undefined
                    i = a.hkpid.localeCompare(b.hkpid);
                }
            }
        }
    }
    else {
        if (a) {
            i = 1;
        }
        else if (b) {
            i = -1;
        }
    }
    return i;
}
exports.hkpMetaComparer = hkpMetaComparer;
function wait(time) {
    return new Promise(resolve => setTimeout(() => resolve(), time));
}
exports.wait = wait;
async function forever(cb, cancel) {
    while (!cancel()) {
        await cb();
    }
}
exports.forever = forever;
function recurring(cb, initialWait = 0) {
    let cancelled = false;
    setTimeout(() => {
        if (!cancelled) {
            forever(cb, () => cancelled);
        }
    }, initialWait);
    return () => (cancelled = true);
}
exports.recurring = recurring;
exports.wochentage = [
    { id: 'mo', name: 'Montag', nr: 1 },
    { id: 'di', name: 'Dienstag', nr: 2 },
    { id: 'mi', name: 'Mittwoch', nr: 3 },
    { id: 'do', name: 'Donnerstag', nr: 4 },
    { id: 'fr', name: 'Freitag', nr: 5 },
    { id: 'sa', name: 'Samstag', nr: 6 },
    { id: 'so', name: 'Sonntag', nr: 7 },
];
function getStunden(oz) {
    try {
        let von = base_1.TimeItem.parse(oz.von);
        let bis = base_1.TimeItem.parse(oz.bis);
        if (von && bis) {
            return base_1.TimeItem.diff(bis, von);
        }
    }
    catch (err) {
        console.error(err);
    }
    return 0;
}
exports.getStunden = getStunden;
function arrayMove(arr, fromIndex, toIndex) {
    const element = arr[fromIndex];
    arr.splice(fromIndex, 1);
    arr.splice(toIndex, 0, element);
}
exports.arrayMove = arrayMove;
// log without reactive getters/setters
function logVueReactiveObject(name, obj) {
    console.log(name, unreactiveVueObject(obj));
}
exports.logVueReactiveObject = logVueReactiveObject;
function unreactiveVueObject(obj) {
    try {
        return JSON.parse(JSON.stringify(obj));
    }
    catch (e) {
        // if circular references
        return obj;
    }
}
exports.unreactiveVueObject = unreactiveVueObject;
function adminLevelString(level) {
    switch (level) {
        case types_1.ADMIN_LEVEL.NONE:
            return 'Normaler Benutzer';
        case types_1.ADMIN_LEVEL.PRAXISMANAGER:
            return 'Admin ohne Benutzerverwaltung';
        case types_1.ADMIN_LEVEL.CLIENT_ADMIN:
            return 'Admin mit Benutzerverwaltung';
        case types_1.ADMIN_LEVEL.ROSE_TEAM:
            return 'rose-Team';
        case types_1.ADMIN_LEVEL.SOLUTIO_TEAM:
            return 'solutio-Team';
    }
}
exports.adminLevelString = adminLevelString;
function navigateWithViewTransition(performNavigation) {
    // causes crashes on chrome as of 2024-08
    const viewTransitionSupported = false && !!document.startViewTransition;
    if (!viewTransitionSupported) {
        performNavigation();
        return;
    }
    return document.startViewTransition(() => {
        performNavigation();
    });
}
exports.navigateWithViewTransition = navigateWithViewTransition;
const germanTitles = [
    'Dr.',
    'Prof. Dr.',
    'Prof.',
    'Dipl.-Ing.',
    'Dr. Ing.',
    'Dr. med.',
    'Dr. rer. nat.',
    'Dr. phil.',
    'Dr. jur.',
    'Dr. oec.',
    'Dr. rer. pol.',
    'Dr. theol.',
    'Dipl.-Kfm.',
    'Dipl.-Psych.',
    'Dipl.-Soz.',
];
function fakeTitle(onlyDr = false) {
    if (onlyDr) {
        return Math.random() < 0.5 ? undefined : 'Dr.';
    }
    return Math.random() < 0.5 ? undefined : (0, lodash_1.sample)(germanTitles);
}
exports.fakeTitle = fakeTitle;
