"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dauerFilter = void 0;
const lodash_1 = require("lodash");
const rose_dayjs_1 = require("../rose-dayjs");
exports.dauerFilter = {
    filters: {
        dauer(dauer, dauerIsMinutes = false, log = false) {
            const duration = rose_dayjs_1.roseDayjs.duration(dauer, dauerIsMinutes ? 'minutes' : 'seconds');
            if (log) {
                console.log(duration.format());
            }
            if (duration.asHours() >= 1) {
                if (duration.minutes() > 0) {
                    return duration.format('H[h] m[m]');
                }
                return duration.format('H[h]');
            }
            else if (duration.asMinutes() >= 1) {
                return duration.format('m[m]');
            }
            return duration.format('s[s]');
        },
        dauerShort(dauer, dauerIsMinutes = false, trimMonthZeroes = false, trimDayZeroes = false, trimHourZeroes = false, trimMinuteZeroes = false, showSeconds = false, log = false) {
            const duration = rose_dayjs_1.roseDayjs.duration(dauer, dauerIsMinutes ? 'minutes' : 'seconds');
            if (log) {
                console.log(duration, duration.format());
            }
            // no months
            const f = duration.format(showSeconds ? 'MM:DD:HH:mm:ss' : 'MM:DD:HH:mm');
            const parts = (0, lodash_1.split)(f, ':');
            if ((showSeconds && parts.length === 5) || parts.length === 4) {
                const [MM, DD, HH, mm] = (0, lodash_1.map)(parts, p => parseInt(p, 10));
                if (MM > 0) {
                    return `${DD + 30 * MM}:${(0, lodash_1.padStart)(HH + '', 2, '0')}:${(0, lodash_1.padStart)(mm + '', 2, '0')}`;
                }
            }
            if (trimMinuteZeroes) {
                return f.replace(/^00:00:00:00:/, '');
            }
            if (trimHourZeroes) {
                return f.replace(/^00:00:00:/, '');
            }
            if (trimDayZeroes) {
                return f.replace(/^00:00:/, '');
            }
            if (trimMonthZeroes) {
                return f.replace(/^00:/, '');
            }
            return f;
        },
    },
};
