"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fromNowFilter = void 0;
const rose_dayjs_1 = require("../rose-dayjs");
exports.fromNowFilter = {
    filters: {
        fromNow(date, markDirty) {
            if (date) {
                let m = (0, rose_dayjs_1.roseDayjs)(date);
                if (m.isValid()) {
                    return m.fromNow();
                }
                return 'n/a';
            }
            return '-';
        },
    },
};
