"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createProfileApi = void 0;
function createProfileApi(api) {
    return {
        loadClientProfile: (cid) => api.metrics.get(`/api/profile`, { clientIdHeader: cid }),
        updateClientProfile: (profile) => api.metrics.put(`/api/profile`, profile),
        getR4cHostMapping: () => api.metrics.get(`/api/profile/r4chostmapping`),
        createNewProfile: (newProfile) => api.metrics.post('api/profile/newclient', newProfile),
        createClientId: (prefix) => api.metrics.get('/admin/createclientid/' + prefix).then(res => res.data),
        getAvailableViews: () => api.metrics.get('/api/views?all=true'),
        updateClient: (updatedClient) => api.metrics.put(`/admin/client`, updatedClient),
        deprecateClient: (cid) => api.metrics.get(`/admin/client/deprecate/${cid}`),
        createTrelloContactCard: (cid) => api.metrics.post(`/api/profile/createcontacttrellocard/${cid}`),
        createHelpdeskInfo: (cid) => api.metrics.post(`/api/profile/createhelpdeskinfo/${cid}`),
        setHelpdeskId: (cid, helpdeskId) => api.metrics.post(`/api/profile/helpdeskid/${cid}`, { helpdeskid: helpdeskId }),
        getProfilesWithTags: () => api.metrics.get(`/api/profileswithtags`),
        getProfilesWithLocation: () => api.metrics.get(`/api/profileswithlocation`),
        // lizenzen
        getLizenzen: (cid) => api.metrics.get(`/api/profile/lizenzen/${cid}`),
        createLizenz: (cid, key, von, bis, bemerkung) => api.metrics.post(`/api/profile/lizenzen/`, { cid, key, von, bis, bemerkung }),
        updateLizenz: (lizenz) => api.metrics.put(`/api/profile/lizenzen/`, lizenz),
        deleteLizenz: (id) => api.metrics.delete(`/api/profile/lizenzen/${id}`),
        // dwh
        acceptDWH: (cluster) => api.metrics.post(`/api/profile/acceptdwh`, { cluster }),
        getProfileGroups: () => api.metrics.get(`/api/profile/groups`),
        saveProfileGroup: (group) => api.metrics.post(`/api/profile/group`, group),
    };
}
exports.createProfileApi = createProfileApi;
