"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tzFilter = void 0;
const rose_dayjs_1 = require("../rose-dayjs");
exports.tzFilter = {
    filters: {
        tz(date, format = 'DD.MM.YYYY', timezone = 'GMT') {
            if (date) {
                let m = (0, rose_dayjs_1.roseDayjs)(date);
                if (m.isValid()) {
                    return m.tz(timezone).format(format);
                }
                return 'n/a';
            }
            return '-';
        },
        t(date, format = 'DD.MM.YYYY') {
            if (date) {
                let m = (0, rose_dayjs_1.roseDayjs)(date);
                if (m.isValid()) {
                    return m.format(format);
                }
                return 'n/a';
            }
            return '-';
        },
    },
};
