"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommonTerminInfoResolverService = void 0;
const base_resolver_service_1 = require("./base-resolver.service");
const fakerDe_1 = require("../fakerDe");
const lodash_1 = require("lodash");
const base_resolver_misc_1 = require("./base-resolver.misc");
class CommonTerminInfoResolverService extends base_resolver_service_1.BaseResolverService {
    constructor() {
        super(...arguments);
        this.name = 'termine';
        this.maxRetries = 5;
    }
    extractDataObjects(entry) {
        return entry ? [entry] : [];
    }
    extractId(entry) {
        return entry.extid;
    }
    extractInfoKey(item) {
        return item.extid;
    }
    fakeInfo(dobjs, options) {
        return Promise.resolve((0, lodash_1.map)(dobjs, o => {
            let pn = {
                extid: this.extractId(o) || '',
                kommentar: fakerDe_1.fakerDe.lorem.sentence(10),
            };
            return pn;
        }));
    }
    blurInfo(dobjs, options) {
        return this.fakeInfo(dobjs, options);
    }
    setResolvedInfo(e, getResolvedInfo) {
        const result = { changedIds: [], failedIds: [] };
        if (e) {
            const tmkResult = this.handleTMK(e, getResolvedInfo, result); // only changeid of main entry is important
            if (tmkResult) {
                result.changedIds.push(tmkResult);
            }
        }
        return result;
    }
    handleTMK(tmk, getResolvedInfo, result) {
        return (0, base_resolver_misc_1.handleTMK)(tmk, getResolvedInfo, result);
    }
}
exports.CommonTerminInfoResolverService = CommonTerminInfoResolverService;
