"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createLeistungenApi = void 0;
function createLeistungenApi(api) {
    return {
        getLeistungen: (queryParams) => api.metrics.get(`/api/leistungen`, {
            query: queryParams,
        }),
        getLeistungenTermine: (queryParams) => api.metrics.get(`/api/leistungen/termine`, {
            query: queryParams,
        }),
        saveTerminKommentar: (data) => api.metrics.post(`/api/leistungen/termine/kommentar`, data),
        saveLeistungWVLInfo: (data) => api.metrics.post(`/api/leistungen/wiedervorlage`, data),
    };
}
exports.createLeistungenApi = createLeistungenApi;
