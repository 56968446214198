"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.humanizeDiffFilter = void 0;
const rose_dayjs_1 = require("../rose-dayjs");
exports.humanizeDiffFilter = {
    filters: {
        humanizeDiff(value, prefix = false) {
            let m = (0, rose_dayjs_1.roseDayjs)(value);
            if (value && m.isValid()) {
                let diff = m.diff((0, rose_dayjs_1.roseDayjs)(), 'seconds');
                let dur = rose_dayjs_1.roseDayjs.duration(diff, 'seconds');
                return dur.humanize(prefix);
            }
            return '';
        },
        humanizeFromNow(value, prefix = false) {
            let m = (0, rose_dayjs_1.roseDayjs)(value);
            if (value && m.isValid()) {
                return m.fromNow(!prefix);
            }
            return '';
        },
    },
};
