"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createHKPApi = void 0;
function createHKPApi(api) {
    return {
        getHKP: (extid) => api.metrics.get(`/api/hkp`, { query: { extid } }),
        getHKPs: (params) => api.metrics.get(`/api/hkp`, { query: params }),
        getPARCases: (patids) => api.metrics.post(`/api/hkp/parcases`, { patids }),
        saveHKPManagerInfo: (hkp) => api.metrics.post(`/api/hkp/manager`, hkp),
    };
}
exports.createHKPApi = createHKPApi;
