"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createR4TabletApi = void 0;
function createR4TabletApi(api) {
    return {
        r4cTaskCreationFailed: (errorInfo, rid) => api.r4cInstance.post(`/tablet/r4cTaskCreationFailed`, { errorInfo }, { rid }),
        getTablets: (r4chost) => api.r4c.get(r4chost, `/tablet/gettablets`),
        getTabletRegisterUrl: (r4chost, apiKey) => api.r4c.get(r4chost, `/factoringinit/tabletregisterurl/${apiKey}`),
        cancelTabletTask: (r4chost, tabletToken, info = 'uicancel') => api.r4c.delete(r4chost, `/tablet/deleteTask/${tabletToken}`, { info }),
        unregisterTablet: (r4chost, tabletToken) => api.r4c.get(r4chost, `/factoringinit/unregistertablet/${tabletToken}`),
        renameTablet: (r4chost, tabletToken, newName) => api.r4c.post(r4chost, `/factoringinit/renametablet/${tabletToken}`, { newName }),
        // QrCode
        assignQrCodeTask: (r4chost, tabletToken, terminInfos) => api.r4c.post(r4chost, `/tablet/assignQrCodeTask`, {
            tabletToken,
            terminInfos,
        }),
        updateQrCodeTask: (r4chost, tabletToken, terminInfos) => api.r4c.post(r4chost, `/tablet/updateQrCodeTask`, {
            tabletToken,
            terminInfos,
        }),
        startR4cTask: (request) => api.r4cInstance.post(`/tablet/startR4cTask`, { request }),
        // upload task
        uploadFiles: (tabletToken, uploadConfig) => api.r4cInstance.post(`/tablet/task/uploadFiles`, { tabletToken, uploadConfig }),
        uploadFilesFromDestopR4c: (params) => api.r4cInstance.post(`/tablet/task/uploadFilesFromDestopR4c`, params),
        // ewe task
        shareEweTaskPerMail: (params) => api.r4cInstance.post(`/factoring/ewe/ewePerMail`, params),
    };
}
exports.createR4TabletApi = createR4TabletApi;
