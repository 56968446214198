"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createImportApi = void 0;
function createImportApi(roseApi) {
    return {
        startImport: (incremental, scripts) => roseApi.collector.get(`/api/startimport/${incremental}`, { query: { scripts } }),
        importProgress: () => roseApi.collector.get(`/api/importprogress`, { query: { includelast: true } }),
        getTerminbuchDebug: (behandler, tag) => roseApi.collector.get(`/api/terminbuch/${behandler}/${tag}`),
    };
}
exports.createImportApi = createImportApi;
